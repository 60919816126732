export default {
  data(){
    return{
      selectedItems:[]
    }
  },
  computed:{
    allSelected() {
      return this.selectedItems.length === this.items.length
    }
  },
  methods:{
    clearSelected(){
      this.selectedItems = []
    },
    selectAll(){
      this.allSelected ? this.selectedItems = [] : this.addAllToSelected()
    },
    addAllToSelected(){
      this.selectedItems = this.items.map(item => item.id)
    },
    selectElement(id){
      this.selectedItems.includes(id) ? this.removeFromSelected(id) : this.selectedItems.push(id)
    },
    removeFromSelected(id){
      const index = this.selectedItems.findIndex(wareId => wareId === id )
      this.selectedItems.splice(index, 1)
    },
  }
}