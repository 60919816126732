<template>
  <div
    class="d-flex align-center mb-5 font-weight-bold"
  >
    <v-checkbox
      :input-value="allSelected"
      class="d-flex"
      color="teal lighten-2"
      hide-details
      :disabled="disabledView"
      @change="select"
    />
    Всего товаров (<template v-if="selectedItems.length">
      {{ selectedItems.length }} /
    </template>{{ items.length }})
    <slot />
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props:{
    items:{
      type:Array,
      required:true
    },
    selectedItems:{
      type:Array,
      required:true
    }
  },
  computed:{
    ...mapGetters({
      disabledView:'dt/getVisibility',
    }),
    allSelected(){
      return this.selectedItems.length === this.items.length
    }
  },
  methods:{
    select(){
      this.$emit('select')
    }
  }
}
</script>